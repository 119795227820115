<template>
  <div class="uf-approvalDetail" v-if="detail">
    <div class="uf-approvalDetail__header">
      <van-cell class="uf-approvalDetail__cell" :border="false" :title="`报警状态：${detail.alarm_state_name}`" />
      <div class="uf-approvalDetail__header__bottom">
        <!-- @click="
          $router.push({
            name: $RouterName.EVENTS_LOG,
            params: {
              id
            }
          })
        " -->
        <van-cell center>
          <template #title>
            <div class="uf-approvalDetail__process">
              <div class="uf-approvalDetail__process-date">
                <i>
                  {{ moment(detail.alarm_time_name).format("HH:mm") }}
                </i>
                <b>
                  {{ moment(detail.alarm_time_name).format("YY-MM-DD") }}
                </b>
              </div>
              <div class="uf-approvalDetail__process-i" style="margin-right:5px">
                <i></i>
                <b></b>
              </div>
              <div class="uf-approvalDetail__process-value" v-if="detail.event_log.length > 0">
                <b> {{ detail.event_log[0].show_content }}</b>
              </div>
            </div>
          </template>
        </van-cell>
      </div>
    </div>
    <div class="uf-approvalDetail__info">
      <div class="uf-approvalDetail__info__title">
        {{ detail.alarm_type_name }}
        <van-tag v-if="detail.is_overtime_name" style="margin-left: 5px" color="#FF5B34" plain> 超时</van-tag>
      </div>
      <div class="uf-approvalDetail__info__content" style="display: flex; align-items: center">
        <span>{{ detail.alarm_code }}</span>
      </div>
      <div class="uf-approvalDetail__info__footer">
        {{ detail.alarm_time_name }}
      </div>
    </div>

    <div class="uf-events__title">报警内容</div>
    <div class="uf-events__close">
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">设备编码：</div>
        <div class="uf-events__close-content">
          {{ detail.code_number || "-" }}
        </div>
      </div>
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">设备名称：</div>
        <div class="uf-events__close-content">
          {{ detail.device_name || "-" }}
        </div>
      </div>
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">设备类型：</div>
        <div class="uf-events__close-content">
          {{ detail.device_type_name || "-" }}
        </div>
      </div>
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">设备位置：</div>
        <div class="uf-events__close-content">
          {{ detail.true_place || "-" }}
        </div>
      </div>
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">报警内容：</div>
        <div class="uf-events__close-content">
          {{ detail.alarm_content || "-" }}
        </div>
      </div>
      <template v-for="(iot, index) in detail.iot_params_json" :key="iot.iot_param_code">
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">报警参数{{ index === 0 ? "" : index + 1 }}：</div>
          <div class="uf-events__close-content">{{ iot.param_value }} {{ iot.param_unit }}</div>
        </div>
      </template>
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">报警照片：</div>
        <div class="uf-events__close-content">
          <van-image
            v-for="(photo, index) in detail.pic_url"
            :key="index"
            style="margin-right: 10px"
            width="105px"
            height="90px"
            fit="cover"
            :src="photo"
            @click="ImagePreview([detail.pic_url])"
          />
        </div>
      </div>
      <!-- <div class="uf-events__close-item">
        <div class="uf-events__close-title">报警视频：</div>
        <div class="uf-events__close-content">点击查看</div>
      </div>
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">实时监控：</div>
        <div class="uf-events__close-content">点击查看</div>
      </div> -->
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">当前部件近期报警数量：</div>
      </div>
      <div class="number-box">
        <div class="number-box__item">
          <div class="number-box__number">
            {{ detail.last_day_num || "-" }}
          </div>
          <div class="number-box__title">
            近24小时
          </div>
        </div>
        <div class="number-box__item">
          <div class="number-box__number">
            {{ detail.last_week_num || "-" }}
          </div>
          <div class="number-box__title">
            近7天
          </div>
        </div>
        <div class="number-box__item">
          <div class="number-box__number">
            {{ detail.last_month_num || "-" }}
          </div>
          <div class="number-box__title">
            近30天
          </div>
        </div>
      </div>
    </div>
    <template v-if="Object.keys(detail.handler || {}).length > 0">
      <div class="uf-events__title">处理信息</div>
      <div class="uf-events__close">
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">处理人：</div>
          <div class="uf-events__close-content" style="color:#0065FF;">
            <template v-if="detail.handler?.handler_uid_array.length > 0">
              {{ detail.handler?.handler_uid_array[0].department_name }} -
              {{ detail.handler?.handler_uid_array[0].name }}
            </template>
            <i v-else>-</i>
          </div>
        </div>
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">领取时间：</div>
          <div class="uf-events__close-content">
            {{ detail.handler?.receive_time_name || "-" }}
          </div>
        </div>
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">领取耗时：</div>
          <div class="uf-events__close-content">
            {{ detail.handler?.receive_time_consuming || "-" }}
          </div>
        </div>
        <template v-if="detail.handler?.operation_lists.length > 0">
          <div class="uf-events__close-item">
            <div class="uf-events__close-title">操作：</div>
          </div>
          <div class="operation-list">
            <div
              class="operation-list__item"
              v-for="(operation, index) in detail.handler?.operation_lists"
              :key="index"
            >
              <template v-if="operation.type == 1">
                <div class="operation-list__item-title">
                  转工单
                  {{ operation.fault_time_name }}
                </div>
                <div class="item-content" @click="toOperationDetail(1, operation.id)">
                  <van-image width="40" height="40" :src="require('../../assets/icons/icon_repair.png')" />
                  <div class="item-content__content">
                    <div class="item-content__content-code">
                      {{ operation.orderid }}
                    </div>
                    <div class="item-content__content-name">
                      {{ operation.faulttype_name }}
                    </div>
                  </div>
                  <div
                    class="item-content__button"
                    :class="{
                      yellow: operation.repairstate_name == '待接单'
                    }"
                  >
                    {{ operation.repairstate_name }}
                  </div>
                </div>
              </template>
              <template v-if="operation.type == 2">
                <div class="operation-list__item-title">
                  转审批
                  {{ operation.create_time_name }}
                </div>
                <div class="item-content" @click="toOperationDetail(2, operation.id)">
                  <van-image width="40" height="40" :src="operation.icon" />
                  <div class="item-content__content">
                    <div class="item-content__content-code">
                      {{ operation.code }}
                    </div>
                    <div class="item-content__content-name">
                      {{ operation.name }}
                    </div>
                  </div>
                  <div
                    class="item-content__button"
                    :class="{
                      orange: operation.state_name == '审批中'
                    }"
                  >
                    {{ operation.state_name }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </template>

    <!-- 关闭 -->
    <template v-if="detail.close?.close_time_name">
      <div class="uf-events__title">处理结果</div>
      <div class="uf-events__close">
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">处理时间：</div>
          <div class="uf-events__close-content">
            {{ detail.close.close_time_name }}
          </div>
        </div>
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">处理时长：</div>
          <div class="uf-events__close-content">
            {{ detail.close.close_time_consuming }}
          </div>
        </div>
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">处理结果：</div>
          <div class="uf-events__close-content">
            {{ detail.close.close_result_name }}
          </div>
        </div>
        <div class="uf-events__close-item" v-if="detail.close.fire_alarm_name">
          <div class="uf-events__close-title">火灾报警控制器：</div>
          <div class="uf-events__close-content">
            {{ detail.close.fire_alarm_name }}
          </div>
        </div>
        <div class="uf-events__close-item" v-if="detail.close.fire_linkage_name">
          <div class="uf-events__close-title">消防联动控制器：</div>
          <div class="uf-events__close-content">
            {{ detail.close.fire_linkage_name }}
          </div>
        </div>

        <div class="uf-events__close-item" v-if="detail.close.pic_ids_lists?.length > 0">
          <div class="uf-events__close-title">图片：</div>
          <div class="uf-events__close-content">
            <van-image
              v-for="photo in detail.close.pic_ids_lists"
              :key="photo.id"
              style="margin-right: 10px"
              width="105px"
              height="90px"
              fit="cover"
              :src="photo.photo_thumb_file"
              @click="ImagePreview([photo.photo_file])"
            />
          </div>
        </div>
        <div class="uf-events__close-item">
          <div class="uf-events__close-title">备 注：</div>
          <div class="uf-events__close-content">
            {{ detail.close.opt_content || "-" }}
          </div>
        </div>
      </div>
    </template>

    <div class="uf-events__close" v-if="approvalList.length > 0">
      <div class="uf-events__close-item">
        <div class="uf-events__close-title">审核人：</div>
        <div class="uf-events__close-content">
          <div class="approval-content" @click="approvalState.show = true">
            <van-image
              v-show="index < 6"
              v-for="(approval, index) in approvalList"
              :key="approval.id"
              round
              width="24px"
              height="24px"
              :style="{ marginLeft: index > 0 ? '-6px' : '0px' }"
              :src="approval.head_pic"
            />
            ...
            <div style="margin-left: 5px">共{{ approvalList.length }}人</div>
            <van-icon name="arrow" color="#999999" size="14" style="margin-left: auto" />
          </div>
        </div>
      </div>
    </div>
    <approval v-model:show="approvalState.show" :list="approvalList" />

    <!-- <exanube v-model:show="exanubeState.show" @submit="exanubeState.submit" />
    <praise v-model:show="praiseState.show" @submit="praiseState.submit" />
  
    <approval v-model:show="approvalState.show2" :list="delay_approval_uids_all" /> -->
  </div>
</template>
<script>
import { alarmService } from "@/service";
import { defineComponent, ref, computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { ImagePreview } from "vant";

import Approval from "@/views/events/components/Approval.vue";
import { RouterName } from "@/router";

export default defineComponent({
  name: "AlarmDetail",
  components: {
    Approval
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const detail = ref(null);

    const getDetail = async () => {
      const { data } = await alarmService.detail({
        id: route.query.id,
        get_log: 1,
        button: 1,
        get_manage_handler: 1
      });
      detail.value = data.data;
      console.log(detail.value, data.data, "====");
    };
    getDetail();

    // 审核人弹窗
    const approvalState = reactive({
      show: false
    });
    // 跳转到工单或者审批详情
    const toOperationDetail = (type, id) => {
      if (type === 1) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: `/pages/guaranteeDetil/main?id=${id}`
        });
        return;
      }
      router.push({
        name: RouterName.APPROVAL_DETAIL,
        params: {
          id
        }
      });
    };

    // 页面的审核人
    const approvalList = computed(() => {
      if (!detail.value.close?.approval_uids_array || !detail.value.close?.approval_uids_do_array) return [];
      if (detail.value.close?.approval_uids_array.length > 0) {
        detail.value.close?.approval_uids_array.forEach((item, index) => {
          if (index === 0) {
            item._state = 2;
            return;
          }
          item._state = 3;
        });
      }
      if (detail.value.close?.approval_uids_do_array.length > 0) {
        detail.value.close?.approval_uids_do_array.forEach(item => {
          item._state = 1;
        });
      }
      return [...detail.value.close?.approval_uids_do_array, ...detail.value.close?.approval_uids_array];
    });

    return {
      ImagePreview,
      moment,
      detail,
      toOperationDetail,
      approvalList,
      approvalState
    };
  }
});
</script>
<style lang="less" scoped>
.number-box {
  background: #f3f5f8;
  border-radius: 4px;
  display: flex;
  padding: 18px 0;
  .number-box__item {
    flex: 1;

    .number-box__number {
      font-size: 28px;
      color: #2c3746;
      text-align: center;
      line-height: 28px;
      font-weight: 400;
    }
    .number-box__title {
      margin-top: 12px;
      font-size: 13px;
      color: #6a717b;
      text-align: center;
      line-height: 14px;
      font-weight: 400;
    }
  }
}
.uf-events-detail {
  background: #fff;
  padding: 15px 0;
  margin: 0 15px;
  /deep/ &::after {
    border-bottom: 1px solid #ccd3df;
  }
  .uf-events-detail__title {
    opacity: 0.88;
    font-size: 15px;
    color: #959fac;
    line-height: 24px;
  }
  .uf-events-detail__content {
    margin-top: 10px;
    font-size: 15px;
    color: #2c3746;
    line-height: 24px;
  }
}

.van-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.uf-approvalDetail {
  .operation-list {
    .operation-list__item {
      margin-bottom: 10px;
    }
    .operation-list__item-title {
      opacity: 0.88;
      font-size: 15px;
      color: #2c3746;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .item-content {
      background: #f7f8f9;
      border-radius: 8px;
      padding: 10px 0 10px 10px;
      display: flex;
      .item-content__content {
        flex: 1;
        min-width: 0;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item-content__content-code {
          font-size: 15px;
          color: #2c3746;
          line-height: 15px;
          margin-bottom: 6px;
        }
        .item-content__content-name {
          font-size: 14px;
          color: #2c3746;
          line-height: 15px;
        }
      }
      .item-content__button {
        background-image: linear-gradient(270deg, #3484ff 0%, #0065ff 98%);
        border-radius: 4px 0 0 4px;
        font-size: 13px;
        color: #ffffff;
        height: 24px;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.yellow {
          background-image: linear-gradient(270deg, #ffc65d 0%, #ffa500 98%);
        }
        &.orange {
          background-image: linear-gradient(270deg, #ff8264 0%, #ff5b34 100%);
        }
      }
    }
  }
  .uf-events__title {
    font-size: 15px;
    color: #333333;
    line-height: 40px;
    margin-top: 8px;
    background: #fff;
    padding: 0 15px;
    position: relative;
    &:after {
      position: absolute;
      box-sizing: border-box;
      transform-origin: center;
      content: " ";
      pointer-events: none;
      top: -50%;
      right: -50%;
      bottom: -50%;
      left: -50%;
      border-bottom: 1px solid #ccd3df;
      transform: scale(0.5);
    }
  }
  .uf-events__close {
    background: #fff;
    padding: 15px;
    .uf-events__close-item {
      display: flex;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    &-title {
      opacity: 0.88;
      color: #959fac;
    }
    &-content {
      flex: 1;
      min-width: 0;
      color: #2c3746;
      .approval-content {
        display: flex;
        align-items: center;
      }
    }
  }
  .uf-footer-button__content {
    flex: 2;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0065ff;
    border: 1px #fff solid;
    font-size: 17px;
    border-radius: 4px;
    color: #ffffff;
    &:first-child {
      flex: 1;
      background: #fff;
      color: #0065ff;
      border: 1px #0065ff solid;
    }
    &:nth-child(2) {
      margin-left: 8px;
    }
  }
  .uf-footer-button__popover {
    margin-left: 4px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid #0065ff;
    border-radius: 4px;
    box-sizing: border-box;
    .uf-footer-button__more {
      transform: rotate(90deg);
    }
  }
  /deep/ .uf-form {
    .uf-form-item {
      margin-bottom: 0;
      position: relative;
      &:after {
        position: absolute;
        box-sizing: border-box;
        transform-origin: center;
        content: " ";
        pointer-events: none;
        top: -50%;
        right: calc(-50% + 20px);
        bottom: -50%;
        left: calc(-50% + 20px);
        border-bottom: 1px solid #ccd3df;
        transform: scale(0.5);
      }
    }
    .uf-form-item__label {
      opacity: 0.88;
      font-size: 15px;
      color: #959fac !important;
      margin-bottom: 10px;
    }
  }
}
</style>
