
import { useVModel } from "@vueuse/core";
import { defineComponent, PropType } from "vue";
interface UserInfo {
  department_name: string;
  name: string;
  head_pic: string;
  id: string;
  duty_name: string;
  _state: number;
}
export default defineComponent({
  name: "Approval",
  props: {
    list: {
      type: Array as PropType<UserInfo[]>,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const innerShow = useVModel(props, "show", emit);

    return {
      innerShow
    };
  }
});
